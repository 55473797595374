// eslint-disable-next-line no-restricted-imports
import { Container, H2, FlexContainer, ContainerProps } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"
import { ButtonPrimary } from "#/components/views/atoms/buttons/ButtonPrimary"
import { IlloUnderwaterChecklist } from "#/components/views/atoms/Illustrations"
import React from "react"
import { BodySmall } from "../views/atoms/Bodies"
import { FormattedMessage } from "react-intl"
import { Tile } from "@carrotfertility/carotene-core"

const ImageContainer = styled.div`
  display: flex;
  flex: 0;
`

const HeroMainContent = styled.div`
  display: flex;
  flex: 1;
`

const HeroText = styled.span`
  font-size: 17px;
  line-height: 1.5em;
`

interface RowItemProps extends ContainerProps {
  minWidth: string
}

const RowItem = styled(Container)((props: RowItemProps) => ({
  minWidth: props.minWidth
}))

// This should be able to be handled by a carotene component, but can't
// This should eventually be followed up on
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
function HeroWithImage({ children, image, spacing }) {
  return (
    <FlexContainer justifyContent="flex-end" wrap="wrap" direction="row-reverse">
      <ImageContainer>{image}</ImageContainer>
      <Container width={spacing}>{""}</Container>
      <HeroMainContent>{children}</HeroMainContent>
    </FlexContainer>
  )
}

export function OptOutOrExistingMemberCpHero(): JSX.Element {
  return (
    <Tile
      sx={(theme) => ({
        // In the future, we should use the provided theme colors
        backgroundColor: theme.palette.info.dark
      })}
    >
      <HeroWithImage
        spacing="24px"
        image={
          <Container width="228px">
            <IlloUnderwaterChecklist />
          </Container>
        }
      >
        <RowItem minWidth="239px">
          <Container stack="medium">
            <H2 stack="tiny" variant="secondary">
              <FormattedMessage defaultMessage="Your Carrot Plan" />
            </H2>
            <BodySmall font="publico-text" color="white">
              <HeroText>
                <FormattedMessage defaultMessage="Update us about your journey so we can craft a free, personalized plan to support you." />
              </HeroText>
            </BodySmall>
          </Container>
          <ButtonPrimary to="/talk-to-carrot/get-your-carrot-plan">
            <FormattedMessage defaultMessage="Get your Carrot Plan" />
          </ButtonPrimary>
        </RowItem>
      </HeroWithImage>
    </Tile>
  )
}
